import ahoy from 'ahoy.js';

ahoy.configure({
  visitsUrl: '/info/visits',
  eventsUrl: '/info/events',
});

ahoy.trackClicks('a, .btn, .clickable');
ahoy.trackSubmits('form');
document.addEventListener('turbolinks:load', () => {
  ahoy.trackView();
});
